<template>
  <footer class="footer">
    <div class="footer-container">
      <!-- Логотип -->
      <div data-aos="fade-right" class="footer-logo">
        <img src="@/assets/logo/Logo-Full_white-reverse.svg" alt="Logo" class="logo" />
        <!-- Контактная информация -->
        <div class="footer-lower">
          <div data-aos="fade-right" class="contact-us">
            <h1 class="contact-title title">{{ $t('contact') }}</h1>
            <p class="contact-item">
              <strong>{{ $t('tel') }}:</strong>
              <a :href="`tel:${contacts.phone}`">{{ contacts.phone }}</a>
            </p>
            <p class="contact-item">
              <strong>{{ $t('email') }}:</strong>
              <a :href="`mailto:${contacts.email}`"> {{ contacts.email }}</a>
            </p>
          </div>
        </div>
      </div>

      <!-- Меню -->
      <nav data-aos="fade-down" class="footer-menu">
        <ul class="menu-ul">
          <li><a @click="scrollToElement($event, 'about'); closeMenu()">{{ $t('about') }}</a></li>
          <li><a @click="scrollToElement($event, 'service'); closeMenu()">{{ $t('service') }}</a></li>
          <li><a @click="closeMenu(); openModal()">{{ $t('contact') }}</a></li>
          <li><a @click="scrollToElement($event, 'feedback'); closeMenu()">{{ $t('feedback') }}</a></li>
          <li><router-link to="/Portfolio"><a @click="closeMenu()">{{ $t('our_videos') }}</a></router-link></li>
        </ul>
      </nav>

      <!-- Социальные иконки -->
      <div data-aos="fade-left" class="social-icons">
        <a v-if="contacts.socials.facebook.url" :href="contacts.socials.facebook.url" target="_blank">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a :href="contacts.socials.instagram.url" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a :href="contacts.socials.youtube.url" target="_blank">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>

    <!-- Разделительная линия и текст -->
    <div class="footer-bottom">
      <hr class="divider" />
      <div class="footer-bottom-text">
        <p>© 2024 Stars Station Studio. All Rights Reserved.</p>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import contacts from "@/contacts.json";

export default {
  name: "AppFooter",
  data() {
    return {
      contacts
    };
  },
  methods: {
    scrollToElement(event, id) {
      event.preventDefault();
      this.$nextTick(() => {
        const element = document.getElementById(id);
        if (element) {
          const yOffset = -50; // Регулируемый отступ
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      });
    },
    closeMenu() {
      // Если есть логика закрытия меню (например, на мобильных)
    },
    openModal() {
      // Логика открытия модального окна для контактов
    }
  }
};
</script>



<style scoped>
.footer {
  width: 100%;
  background-color: #191A23;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.footer-logo {
  background-color: #191A23;
  margin-left: 50px;
  position: relative;
  z-index: 2;
}

.footer-logo img {
  width: 180px;
}

.footer-logo,
.footer-menu,
.social-icons,
.contact-us {
  margin-top: 50px;
}

.footer-menu {
  display: flex;
  gap: 40px;
}

.footer-menu ul {
  list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.social-icons {
  display: flex;
    gap: 20px;
    margin-right: 50px;
    flex-direction: column;
}

.footer-lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.contact-us {
  color: white;
}

.contact-title {
  display: inline;
}

.footer-bottom {
  text-align: center;
  padding: 20px 0;
}

.divider {
  border: 0;
  height: 1px;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
}

.footer-bottom-text p {
  color: white;
  font-size: 16px;
  margin: 0;
}

.footer-bottom-text a {
  color: white;
  text-decoration: none;
  margin-left: 10px;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-lower {
    flex-direction: column;
  }
  .footer-logo,
  .contact-us,
  .social-icons {
    margin-right: 0;
    margin-left: 0;
  }
  .contact-us {
    text-align: center;
  }
  .footer-menu ul {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .footer-menu ul li a,
  .contact-us p {
    font-size: 16px;
  }
}
</style>
